<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->

<template>
  <section class="tiny narrow main transparent">
    <section class="v-spaced bordered padded">
      <h2>
        <app-icon name="forum" />&nbsp;
        <translate translate-context="*/*/*/Verb">Get in touch</translate>
      </h2>
      <p>
        <translate translate-context="Help/*/Paragraph">
          You should find the answers to most of your questions in our documentation and FAQ.
        </translate>
      </p>
      <p>
        <translate translate-context="Help/*/Paragraph">
          If it is not the case, or need to get in touch directly, don't hesitate to contact us using the email below or via the support widget in the bottom right corner.
        </translate>
      </p>
      <a :href="`mailto:${$store.state.supportEmail}`">
        {{ $store.state.supportEmail }}
      </a>
    </section>
    <section class="v-spaced bordered padded">
      <h2>
        <app-icon name="book" />&nbsp;
        <translate translate-context="*/*/*">Documentation</translate>
      </h2>
      <p>
        <translate translate-context="Help/*/Paragraph">
          We've put together an extensive documentation that should help you get the most of Alta Call:
        </translate>
      </p>
      <ul>
        <li>
          <router-link to="/help/docs">
            <translate translate-context="Help/*/Paragraph">
              Download, install and configure Alta Call
            </translate>
          </router-link>
        </li>
        <li>
          <router-link to="/help/docs/web">
            <translate translate-context="Help/*/Paragraph">
              Oversee and monitor Alta Call deployments, resolve potential issues
            </translate>
          </router-link>
        </li>
      </ul>
    </section>
    <section class="v-spaced bordered padded">
      <h2>
        <app-icon name="check" />&nbsp;
        <translate translate-context="*/*/Title/Name">Setup checklist</translate>
      </h2>
      <p>
        <translate translate-context="Help/*/Paragraph">
          Once installation is complete, you can use this checklist to ensure everything will work as expected.
        </translate>
      </p>
      <hr class="hidden">
      <router-link to="/help/setup">
        <translate translate-context="*/*/*/Action">Go to checklist</translate>
      </router-link>
    </section>
    <section class="v-spaced bordered padded">
      <h2>
        <app-icon name="help-circle" />&nbsp;
        <translate translate-context="*/*/*">Frequently Asked Questions</translate>
      </h2>
      <p>
        <translate translate-context="Help/*/Paragraph">
          Find answers to the most common questions regarding Alta Call configuration and errors.
        </translate>
      </p>
      <hr class="hidden">
      <router-link to="/help/faq">
        <translate translate-context="*/*/*/Action">Browse our FAQ</translate>
      </router-link>
    </section>
    <section class="v-spaced bordered padded">
      <h2>
        <app-icon name="code-tags" />&nbsp;
        <translate translate-context="*/*/*">REST API</translate>
      </h2>
      <p>
        <translate translate-context="Help/*/Paragraph">
          Use our REST API to interact with Alta Call programmatically from your own scripts and softwares.
        </translate>
      </p>
      <hr class="hidden">
      <router-link to="/account#api">
        <translate translate-context="*/*/*">Manage your API keys</translate>
      </router-link> &nbsp;
      <router-link to="/help/api">
        <translate translate-context="*/*/*/Action">Browse API Documentation</translate>
      </router-link>
    </section>
  </section>
</template>

<script>

export default {
  components: {}
}
</script>
