<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <section class="main transparent">
    <div class="stackable row">
      <div class="three wide column no-print">
        <section class="sticky bordered padded">
          <h4>
            <translate translate-context="*/*/Title/Name">
              Contents
            </translate>
          </h4>
          <toc v-if="renderToc" :content-element="$refs.content" :level="2" />
        </section>
      </div>
      <div class="nine wide column">
        <section class="heavily bordered padded" ref="content">
          <h1>
            <translate translate-context="*/*/*">Documentation for the Alta Call executable</translate>
          </h1>
          <p v-translate translate-context="Documentation/*/*">
            The following documentation describes how to install and use the Alta Call executable.
            If you want to learn how to use our web interface, visit <a href="/help/docs/web" class="internal">our documentation on the topic</a>.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            <em>Alta Call</em> is a software for transforming your voice in real-time.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            The following documentation describes the audio setup, and the very few controls available. If you have a quick question, our <a href="./faq" class="internal">FAQ</a> is also available.
          </p>
          <h2 v-translate translate-context="Documentation/*/Title">Installation</h2>
          <p v-translate translate-context="Documentation/*/*">
            First <a href="/downloads" class="internal">download Alta Call’s installer</a>, and execute it.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            In any doubt, please use the default installation directory and install the default components the installer suggests.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            By default, the installer will install the <em>Alta Call</em> software as well as <em><a href="https://vb-audio.com/Voicemeeter/">Voicemeeter</a></em>, a virtual audio device that will be used to interface <em>Alta Call</em> with your telephony software (the virtual audio device <em><a href="https://vb-audio.com/Cable/">VB-Cable</a></em> can be used instead of <em>Voicemeeter</em> if this one creates any issue. If you do so, please read <em>VB-Cable</em> instead of <em>Voicemeeter</em> in the following documentation).
          </p>
          <p v-translate translate-context="Documentation/*/*">
            You will have to reboot your computer for the software to work properly, as it has to setup the virtual audio device that will output your transformed voice.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            If you encounter unexpected issues during installation or first use of Alta Call, please first check our <a href="./faq" class="internal">FAQ</a> where we address already many little IT details and then do not hesitate to contact us!
          </p>
          <h3 v-translate translate-context="Documentation/*/*" id="install-cli">Command line during installation</h3>
          <p v-translate translate-context="Documentation/*/*">
            For mass deployment, the installation can also be done on the command line. The installer is based on the <em>Inno Setup</em> software. The default command line arguments can be found <a href="https://jrsoftware.org/ishelp/index.php?topic=setupcmdline">here</a>.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            The extra argument <code>/license</code> can also be used to specify your license while installing.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            For example, a full silent command line installation would be:
          </p>
          <code>.\AltaCall-0.9.0-windows10-any.exe /license=THIS-IISS-MMYY-LICE-NSEE-IIDD /verysilent /norestart /suppressmsgboxes /tasks="startmenuentry"</code>
          <p v-translate translate-context="Documentation/*/*">
            Please replace the executable name above with the proper filename you have downloaded and replace the license identifier as well.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            Please replace the license ID as well with the one your received.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            the <code>/tasks="startmenuentry"</code> command asks the installer to launch Alta Call at startup, and avoid the <code>desktopicon</code> task, which can be useful to avoid users to launch Alta Call by mistake.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            The <code>/reboot</code> option force the installer to restart your machine automatically after installing Alta Call.
            i.e : <code>.\AltaCall-0.9.0-windows10-any.exe /license=THIS-IISS-MMYY-LICE-NSEE-IIDD /reboot /verysilent /norestart /suppressmsgboxes /tasks="startmenuentry"</code>
          </p>
          <p v-translate translate-context="Documentation/*/*">
            Finally, the installer <code>AltaCall-0.9.0-windows10-any.exe</code> return an exit code after installation.
          </p>
          <ul>
            <li v-translate translate-context="Documentation/*/*">it returns <code>exit code = 0</code> if setup was successfully run to completion.</li>
            <li v-translate translate-context="Documentation/*/*">it return <code>exit code != 0</code> if setup was failed.</li>
          </ul>	
          <p v-translate translate-context="Documentation/*/*">
            On Windows you can retrieve this exit code after installation via command line by:
          </p>
          <ul>
            <li v-translate translate-context="Documentation/*/*"><code>echo %errorlevel%</code> in command prompt(cmd).</li>
            <li v-translate translate-context="Documentation/*/*"><code>$LASTEXITCODE</code> in powershell.</li>
          </ul>
          <p v-translate translate-context="Documentation/*/*">
            <strong>Note</strong>: In order to obtain the correct return code from the installer, you must launch the installation by command line and display the return code via a script because when you run a GUI application (the installer) at from a command line, the console does not wait for the end of the program, So it returns an incorrect return code, the <code>%errorlevel%</code> or <code>$LASTEXITCODE</code> cannot contain an exit code from the application (installer), because it is not yet finished at that time.
          </p>
          <p v-translate translate-context="Documentation/*/*">
			But if you add the exactly same commands(install + display return code) to a script file (.bat) or (.ps1), it will work. As the batch / powershell file waits for the application to finish.
          </p>
          <p v-translate translate-context="Documentation/*/*">
			Example .bat file :
          </p>
          <p>
          <pre><code>.\AltaCall-0.9.0-windows10-any.exe /license=THIS-IISS-MMYY-LICE-NSEE-IIDD /verysilent /norestart /suppressmsgboxes /tasks="startmenuentry"
echo %errorlevel%</code></pre>
          </p>
          <p v-translate translate-context="Documentation/*/*">
			Example .ps1 file :
          </p>
          <p>
          <pre><code>.\AltaCall-0.9.0-windows10-any.exe /license=THIS-IISS-MMYY-LICE-NSEE-IIDD /verysilent /norestart /suppressmsgboxes /tasks="startmenuentry"
$LASTEXITCODE</code></pre>
          </p>
          <h3 v-translate translate-context="Documentation/*/*">Command line at run time</h3>
          <p v-translate translate-context="Documentation/*/*">
            A few arguments can be provided to the executable (named <code>vmocall.exe</code> in the installation directory), wich are:
          </p>
          <pre><code>-h, --help                           Displays this help.
-v, --version                        Displays version information.
-c                                   Show compilation info and exit.
--license &lt;license key&gt;        Provide a license key, without saving it anywhere.
                                     The format is XXXX-XXXX-XXXX-XXXX-XXXX-XXXX, where
                                   'X' is either a capital letter or a digit.
--license_save_installdir            Save the license key in the installation
                                    directory.
--license_save_userdir               Save the license key in the user's config
                                    directory.
--gen_install_id                     Generate the installation ID if it doesnt exist
                                    and save it.
--norun                              Apply the various options above and exit, without
                                    running the software.
--start_locked                       Start the software locked.
--start_minimized                    Start the software minimized in the Operating
                                    System tray.
--ph, --proxy_host host              Define proxy host for some customers.
--pp, --proxy_port port              Define proxy port for some customers.
--puser, --proxy_username username   Define proxy username for some customers.
--ppwd, --proxy_password password    Define proxy password for some customers.</code></pre>
          <p>
            For deployment in a call center, it is highly recommender to start Alta Call with the following two arguments: <code>--start_locked --start_minimized</code>
          </p>
          <h2 v-translate translate-context="Documentation/*/*">Network Setup and Telemetry</h2>
          <p v-translate translate-context="Documentation/*/*">
            <em>Alta Call</em> needs basic internet access to access our servers and work properly.
          </p>
          <p>
            Some fundamental information first:
          </p>
          <ul>
            <li v-translate translate-context="Documentation/*/*"><strong>NO</strong> audio is transferred between the Alta Call software and our servers. The audio stream and its content is recorded from the input device, processed, sent through the output device and forgotten in a time frame of about 40ms (a 25th of a second, as quickly as a movie image appear and disappear on a screen).</li>
            <li v-translate translate-context="Documentation/*/*">All <em>Alta Call</em>'s communication with our servers is fully encrypted (this <em>communication</em> is described below).</li>
          </ul>
          <p v-translate translate-context="Documentation/*/*">
            The protocol used is HTTPS over TCP and UDP using the standard port <code>443</code>. The desinations follow the pattern: <code>*.alta-voce.tech</code>
          </p>
          <p v-translate translate-context="Documentation/*/*">
            If <em>Alta Call</em> cannot access our servers, the application will automatically switch to a passthrough mode (no  transformation will be applied, with minimal latency). Namely, the application will <strong>never</strong> close on its own.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            The information exchanged between <em>Alta Call</em> and our server is made of:
          </p>
          <ul>
            <li v-translate translate-context="Documentation/*/List.Item">Processing settings (ex. whether to apply loudness normalisation)</li>
            <li v-translate translate-context="Documentation/*/List.Item">Processing statistics (ex. input and output volumes, over/under-runs, saturation)</li>
            <li v-translate translate-context="Documentation/*/List.Item">Algorithm parameters (ex. thresholds, fine-tuning parameters)</li>
            <li v-translate translate-context="Documentation/*/List.Item">GUI settings (ex. mode and scaling selection, remote lock)</li>
            <li v-translate translate-context="Documentation/*/List.Item">Software IDs (ex. process id, installation id, machine id, user id, license id), all those keys are random pseudo-anonymised values. They help us to distinguish processes, installations, machines and users. We are <strong>not</strong> able to deduce any relevant information from any single key alone.</li>
            <li v-translate translate-context="Documentation/*/List.Item">Software statistics (ex. CPU usage, size of data transferred, uptime)</li>
            <li v-translate translate-context="Documentation/*/List.Item">Software debugging information (ex. version, operating system type and architecture, local IP address)</li>
          </ul>
          <p v-translate translate-context="Documentation/*/*">
            Note that, by default, we are not able to deduce any personal information based on the data that we retrieve. We therefore are dependent on the <a href="https://gdpr.eu"><strong>GDPR</strong></a> only by association with our client’s data.
          </p>
          <p>
            Options can be discussed to make the machines and user names apparent.
          </p>
          <h2 v-translate translate-context="Documentation/*/*">Running Alta Call</h2>
          <p v-translate translate-context="Documentation/*/*">
            To get the best quality from Alta Call, (best sound with lowest latency), it might be necessary to run the application with Administrative privileges.
          </p>
          <p>
            If you cannot run <em>Alta Call</em> with Administrative privileges, you might have an option to offer <strong>CPU priority</strong> to Alta Call in your operating system settings. Alta Call does not need lots of CPU. It needs a little though very regularly.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            If Alta Call is locked on first run, you will need the admin PIN to unlock it, which is: <strong>9876</strong> (default PIN, that you might have changed)
          </p>
          <h2 v-translate translate-context="Documentation/*/*">Audio setup</h2>
          <p v-translate translate-context="Documentation/*/*">
            You first have to open the <em>Audio Settings</em> tab on the right side of the window in order to select the sound input you want to transform. The software then looks like in the picture below:
          </p>
          <img loading="lazy" src="https://old.alta-voce.tech/wp-content/uploads/2020/09/gui_audio_settings.png" alt="">
          <p v-translate translate-context="Documentation/*/*">
            <em><strong>Audio API</strong></em>: This combo box allows to select the underlying operating system audio interface.
          </p>
          <p>
            Under Windows, you should have access to <em>WASAPI</em> (named <code>wasapi</code>) and DirectSound (named <code>ds</code>). <em>WASAPI</em> should be working just fine.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            <em><strong>Capture Device</strong></em>: This combo box lists all the available audio input devices that you can select for sending sound to be transformed by <em>Alta Call</em>. It might be a headset mic, a standalone microphone, etc. select the one you want.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            <strong><em>Playback Device</em></strong>: This combo box lists all the available audio output devices that you can select for <em>Alta Call</em> to send its transformed sound to. It might be <em>Voicemeeter</em> for interfacing with another software, or a headset, etc. select the one you want.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            <em><strong>Preferred sampling rate</strong></em>: You can select here the sampling rate you want <em>Alta Call</em> to work with. It mostly depends on your application.
          </p>
          <p>
            For best performance and stability, the value should be an integer multiple of 1000.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            <strong><em>Refresh Devices Lists</em></strong>: <em>Alta Call</em> scans the available capture and playback devices when it starts. If you plug an audio interface afterwards, please press this button to refresh the devices lists above and to see your new device.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            <em><strong>Apply/Reset</strong></em>: Once everything is setup, you have to press this button to apply any changes you’ve made in the <em>Device Settings</em> box. If no change has been made, pressing this button will simply reset the audio streams.
          </p>
          <h2 v-translate translate-context="Documentation/*/*">Volumes</h2>
          <p v-translate translate-context="Documentation/*/*">
            On the left side of the window, you will find two vertical bars and a slider:
          </p>
          <p v-translate translate-context="Documentation/*/*">
            As soon as the audio setup is working, blue vertical bars on the left will fluctuate according to how loud is the input sound (ex. how loud you speak in the microphone).
          </p>
          <p v-translate translate-context="Documentation/*/*">
            The bar on the left represents the loudness of the input sound. The bar on the right represents the loudness of the sound transformed.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            If the bar on the left is grey, it means there is no input sound. In this case, if you’re using a microphone as sound input, please check the hardware connectivity; check the input volume in the mixer table of your operating system is high enough; and check the microphone is not muted.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            Alta Call automatically output the same loudness as the one found in the sound input. So that there is no need of volume correction.
          </p>
          <h3 v-translate translate-context="Documentation/*/*">Possible audio setups</h3>
          <h4 v-translate translate-context="Documentation/*/*">
            For transforming the voice of an agent in a call center
          </h4>
          <p>
            The capture device should be the agent’s headset having microphone.
          </p>
          <p>
            The playback device should be the input of the <em>Voicemeeter</em> that is used by the telephony software.
          </p>
          <p>
            The preferred sampling rate should be 16000.
          </p>
          <h4 v-translate translate-context="Documentation/*/*">
            For listening to your own transformed voice (which can be useful for verification purposes)
          </h4>
          <p v-translate translate-context="Documentation/*/*">
            The capture and playback devices should be the same, eg. a headset with microphone.
          </p>
          <h2 v-translate translate-context="Documentation/*/*">Transformation settings</h2>
          <p v-translate translate-context="Documentation/*/*">
            With the <em>Transformation</em> tab opened, the software looks like in this picture:
          </p>
          <img loading="lazy" src="https://old.alta-voce.tech/wp-content/uploads/2020/09/gui_main.png" alt="">
          <h4 v-translate translate-context="Documentation/*/*">
            Mode
          </h4>
          <p v-translate translate-context="Documentation/*/*">
            Beside the Transformation effects (Smile, Intelligibility, Happy, etc.), you will find 4 other modes that might be useful to you:
          </p>
          <ul>
            <li v-translate translate-context="Documentation/*/List.Item"><strong>Passthrough</strong>: In this mode, no transformation is applied, Alta Call basically do nothing to the input sound.</li>
            <li v-translate translate-context="Documentation/*/List.Item"><strong>Test output</strong>: In this mode, a female voice will constantly repeat a text. This can be used to check that <em>Alta Call</em> is indeed working through your telephony pipeline.</li>
            <li v-translate translate-context="Documentation/*/List.Item"><strong>Mute output</strong>: In this mode, there is no output sound. This can be useful if a machine happens to have multiple <em>Alta Call</em> session working at the same time (eg. a Windows session has not been closed and another operator opened a new one. There first session should thus be muted).</li>
            <li v-translate translate-context="Documentation/*/List.Item"><strong>Stop processing</strong>: This mode completely stop the processing of the input sound and free any audio device that it might have been using.</li>
          </ul>
          <p v-translate translate-context="Documentation/*/*">
            For some transformation modes, a horizontal slider appears below the mode selection box. With this slider, you can scale the <em>effect size</em>, how strong is the effect.
          </p>
          <h2 v-translate translate-context="Documentation/*/*">Menus</h2>
          <p v-translate translate-context="Documentation/*/*">
            The software’s menu is available below the top left corner of the window. It offers the following commands:
          </p>
          <h4 v-translate translate-context="Documentation/*/*">File</h4>
          <ul>
            <li v-translate translate-context="Documentation/*/List.Item"><strong>Remote Control</strong>: All the settings can be locked with an administrator PIN so that the <em>Alta Call</em> software is only controlled from the Client Space. Please contact us or your employer if you’re supposed to know this PIN, but you don’t have it.</li>
            <li v-translate translate-context="Documentation/*/List.Item"><strong>Change the license key</strong>: You might need to attach the <em>Alta Call</em> software to a given license key. You can do it here.</li>
            <li v-translate translate-context="Documentation/*/List.Item"><strong>Quit</strong>: Close <em>Alta Call</em> completely.</li>
          </ul>
          <p v-translate translate-context="Documentation/*/*">
            <strong>Warning</strong>: <strong><em>Quit</em></strong> will not minimize the settings panel in the system tray. This will fully <strong>shutdown</strong> <em>Alta Call</em>. Consequently, any audio software that was connected to <em>Alta Call</em> will <strong>not</strong> receive sound anymore.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            In order to minimize the window in the system tray, please simply click on the close button of the window, on the top right corner (or top left depending on your operating system).
          </p>
          <h4 v-translate translate-context="Documentation/*/*">Help</h4>
          <p v-translate translate-context="Documentation/*/*">
            <strong><em>About</em></strong> shows:
          </p>
          <ul>
            <li v-translate translate-context="*/*/*">Version</li>
            <li v-translate translate-context="Documentation/*/List.Item">License key used</li>
            <li v-translate translate-context="Documentation/*/List.Item">User’s and machine’s pseudo-anonymization key.</li>
            <li v-translate translate-context="Documentation/*/List.Item">Configuration files saved on the user’s machine</li>
            <li v-translate translate-context="Documentation/*/List.Item">Some legal terms, please see the contract for the full legal rights and duties.</li>
          </ul>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
import Toc from '@/components/TOC'
export default {
  components: {
    Toc,
  },
  data () {
    return {
      renderToc: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.renderToc = true
    })
  }
}
</script>
