<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <div>
    <template v-if="$store.state.helpScoutBeaconId">
      <component
        :is="'script'"
        type="text/javascript"
      >
        !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
      </component>
    </template>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>

export default {
  components: {},
  computed: {
    beaconUserObject () {
      if (!this.$store.state.authenticated) {
        return null
      }
      let d = {}
      d.name =  this.$store.state.user.name
      d.email = this.$store.state.user.email
      if (this.$store.state.organization) {
        d.company = this.$store.state.organization.name
      }
      let g = this.$store.state.selectedGroup || {}
      d["group-id"] = g.id || ''
      d["group"] = g.name || ''
      d["language"] = this.$store.state.currentLanguage
      return d
    }
  },
  mounted () {
    window.Beacon('init', this.$store.state.helpScoutBeaconId)
    this.identify(this.beaconUserObject)
  },
  beforeUnmount () {
    window.Beacon('destroy')
  },
  methods: {
    identify (v) {
      if (!window.Beacon) {
        return
      }
      if (!v) {
        window.Beacon('logout')
      } else {
        window.Beacon('identify', v)
      }
    }
  },
  watch: {
    beaconUserObject (v) {
      this.identify(v)
    }
  }
}
</script>
