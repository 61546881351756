<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <section class="main transparent">
    <div class="stackable row">
      <div class="three wide column no-print">
        <section class="sticky bordered padded">
          <h4>
            <translate translate-context="*/*/Title/Name">
              Contents
            </translate>
          </h4>
          <toc v-if="renderToc" :content-element="$refs.content" :level="2" />
        </section>
      </div>
      <div class="nine wide column">
        <section class="heavily bordered padded" ref="content">
          <h1>
            <translate translate-context="*/*/*">Alta Call Software Overview</translate>
          </h1>
          <p v-translate translate-context="Documentation/*/*">
            This page describes Alta Call architecture and behaviour. It is intended to give you a better technical understanding of the solution and its components.
          </p>
          <h2>
            <translate translate-context="*/*/*">Software components</translate>
          </h2>
          <p v-translate translate-context="Documentation/*/*">
            Alca Call is an executable installed on agents device, that handles all the audio and transformations applied on the agents voice.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            This executable communicates with our APIs to fetch and store its configuration, submit telemetry and other device-related data that is needed to monitor and adapt the software's behaviour.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            All communications happen using the HTTPS protocol, over port 443.
          </p>
          <h2>
            <translate translate-context="*/*/*">Client/server data flow</translate>
          </h2>    
          <p v-translate translate-context="Documentation/*/*">
            This diagram details how data flows from and to the Alta Call executable
          </p>
          <p v-translate translate-context="Documentation/*/*">
            For more information regarding our data processing, please visit our <a href="/privacy" class="internal">Privacy page</a>.
          </p>
          <img
            loading="lazy" class="mx-regular" :src="`${publicPath}graphs/data-flow.svg`" alt="Client/server data flow">
          <h2>
            <translate translate-context="*/*/*">Network flow matrix</translate>
          </h2>
          <table class="simple">
            <thead>
              <th>
                <translate translate-context="*/*/*">Source</translate>
              </th>
              <th>
                <translate translate-context="*/*/*">Destination</translate>
              </th>
              <th>
                <translate translate-context="*/*/*">Port</translate>
              </th>
              <th>
                <translate translate-context="*/*/*">Expected traffic (B/s)</translate>
              </th>
              <th>
                <translate translate-context="*/*/*">Description</translate>
              </th>
            </thead>
            <tbody>
              <tr v-for="(flow, i) in flowMatrix" :key="i">
                <td>
                  {{ flow.from }}
                </td>
                <td>
                  {{ flow.to }}
                </td>
                <td>
                  {{ flow.port }}
                </td>
                <td>
                  <template v-if="flow.bps">{{ flow.bps }}</template>
                  <translate v-else>Variable</translate>
                </td>
                <td>
                  {{ flow.description }}
                </td>
              </tr>
            </tbody>
          </table>
          <h2>
            <translate translate-context="*/*/*">Alta Call updates</translate>
          </h2>
          <p v-translate translate-context="Documentation/*/*">
            Updates for the Alta Call executable must be applied manually by your IT team, on each agents' device. 
          </p>
          <p v-translate translate-context="Documentation/*/*">
            Prior to any massive update, it is a good practice to make a run test: install it first on a subset of devices and run it for several days. Only after the run test, massive deployment should be considered.
          </p>
          <ul>
            <li>
              <router-link to="/downloads">
                <translate translate-context="*/*/*/Noun">Downloads</translate>
              </router-link>
            </li>
            <li>
              <router-link to="/help/docs">
                <translate translate-context="*/*/*/Noun">Alta Call documentation</translate>
              </router-link>
            </li>
          </ul>
          <p v-translate translate-context="Documentation/*/*">
            Support for automated or semi-automated updates is being discussed. 
          </p>
          <h2>
            <translate translate-context="*/*/*">Security practices, processes and technologies in use at Alta Voce</translate>
          </h2>
          <p v-translate translate-context="Documentation/*/*">
            This section describes the technical and organizational processes in place at Alta Voce to ensure data privacy, security, consistency and integrity.
          </p>
          <h3 v-translate translate-context="Documentation/*/*">Access control and secrets</h3>
          <p v-translate translate-context="Documentation/*/*">
            Our database and application servers are accessible only to trusted individuals inside the company, and such access can be revoked instantly and individually. All infrastructure-related tasks happen over strongly encrypted connections and protocols.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            Our codebases doesn't contain any hardcoded secret that could be used for a data breach, in the event someone gains access to our code repositories. Access to such secrets is restricted to trusted individuals and protected by two factor authentification.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            Individuals within our research and development team can only access the repositories they need for their work.
          </p>
          <h3 v-translate translate-context="Documentation/*/*">Software upgrades</h3>
          <p v-translate translate-context="Documentation/*/*">
            Our servers are running the latest stable version of our operating system, and security patches for installed software are applied automatically when available.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            Our own applications are rebuilt regularly with the latest stable versions of our libraries and dependencies. Bugfixes and upgrades of our dependencies are deployed immediatly through continuous deployment, to ensure potential vulnerabilites are fixed as soon as possible.
          </p>
          <h3 v-translate translate-context="Documentation/*/*">Automatic testing</h3>
          <p v-translate translate-context="Documentation/*/*">
            Critical paths for our applications, such as access control, permission checks, API access and data modification are continuously tested to ensure we don't introduce unexpected regressions or change in behaviour when we update the codebase. Such tests are run automatically against each new version of the code and before each deployment. Failing tests prevent new deployments until they are fixed.
          </p>
          <h3 v-translate translate-context="Documentation/*/*">Monitoring</h3>
          <p v-translate translate-context="Documentation/*/*">
            We have several monitoring tools in place to observe and understand the behaviour of our application and infrastructure. This allows us to detect and troubleshoot potential bugs, errors and data breach. All our backend applications report error logs and crashes immediately so that we can investigate and fix potential issues.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            Our infrastructure team is also notified immediately and automatically whenever one of our application is down, to ensure we can react accordingly.
          </p>
          <h3 v-translate translate-context="Documentation/*/*">Sandboxing</h3>
          <p v-translate translate-context="Documentation/*/*">
            Our backend applications run in sandboxed environments to reduce the attack surface, and can only communicate with one another when needed. Such communications are protected with encryption and strong, unique passwords that can be revoked and renewed as necessary.
          </p>
          <h3 v-translate translate-context="Documentation/*/*">Administration tools</h3>
          <p v-translate translate-context="Documentation/*/*">
            Some of our team members need to browse our clients data and perform some operations on this data. This happens through a single application, developped for that purpose that is only available to the corresponding team members, and protected with communications encryption and individual access control. Such access can be revoked for any team member, at any time, if needed.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            We also keep audit logs for such connections so we can understand and troubleshoot unusual activity.
          </p>
          <h3 v-translate translate-context="Documentation/*/*">Retention</h3>
          <p v-translate translate-context="Documentation/*/*">
            We only collect and keep the data that is necessary for our services to work. This is enforced through several automatic tasks and constraints, both at the application and database level:
          </p>
          <ul>
            <li v-translate translate-context="Documentation/*/*">error and crash reports are automatically purged after 90 days</li>
            <li v-translate translate-context="Documentation/*/*">user and/or client deletion automatically cascade to related rows in the database</li>
            <li v-translate translate-context="Documentation/*/*">expired data such as telemetry entries, audio recordings and support requests is purged from our database on a daily basis, through automated tasks</li>
          </ul>
          <h3 v-translate translate-context="Documentation/*/*">Backups</h3>
          <p v-translate translate-context="Documentation/*/*">
            All data is backed up automatically on a daily basis. Such backups are encrypted then uploaded to a separate server, in a different datacenter, and kept for three months to ensure we can restore properly if needed.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            Expired backups are pruned automatically from this remote storage, and the decryption keys are known only by our infrastructure team members.
          </p>
          <h3 v-translate translate-context="Documentation/*/*">Consent</h3>
          <p v-translate translate-context="Documentation/*/*">
            Users can grant or remove consent for some or all types of data collection. Such consent is processed and stored in our database so that all our applications can behave accordingly and comply with the user choice and GDPR regulation.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            For more information regarding our data processing, please visit our <a href="/privacy" class="internal">Privacy page</a>.
          </p>
          <h3 v-translate translate-context="Documentation/*/*">Data request and removal</h3>
          <p v-translate translate-context="Documentation/*/*">
            Users and clients can contact us by email to request access to their data, or deletion of this data.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            When we receive such requests, we first ensure the request comes from the adequate party, by verifying several identifiers, then our infrastructure team proceed to the extraction or deletion of the data through scripts that are written and tested for this purpose.
          </p>
          <h3 v-translate translate-context="Documentation/*/*">Review and enhancements</h3>
          <p v-translate translate-context="Documentation/*/*">
            At least every three months, Alta Voce's Data Protection Officer conducts a formal review of this document and the corresponding practices within the organization. This processes is meant to ensure what's described here match what is actually in place, and conduct the necessary recommendations and changes, based on the state of the art and legal requirements.
          </p>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
import Toc from '@/components/TOC'
export default {
  components: {
    Toc,
  },
  data () {
    return {
      renderToc: false,
      publicPath: process.env.BASE_URL,
      flowMatrix: [
        {
          from: "Alta Call (local)",
          to: "ac-v1.api.alta-voce.tech",
          port: 443,
          bps: 80,
          description: "Update configuration, consent, submit telemetry and device data"
        },
        {
          from: "ac-v1.api.alta-voce.tech",
          to: "Alta Call (local)",
          port: 443,
          bps: 10,
          description: "Receive configuration updates"
        },
        {
          from: "Alta Call (local)",
          to: "client-space.api.alta-voce.tech",
          port: 443,
          bps: 5,
          description: "Send real-time updates such as local state and configuration changes"
        },
        {
          from: "client-space.api.alta-voce.tech",
          to: "Alta Call (local)",
          port: 443,
          bps: 5,
          description: "Send real-time updates such as remote configuration changes"
        },
        {
          from: "Alta Call (local)",
          to: "sentry.alta-voce.tech",
          port: 443,
          bps: null,
          description: "Send error logs and crash reports"
        }
      ]
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.renderToc = true
    })
  }
}
</script>
