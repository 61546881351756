<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <section class="main transparent">
    <div class="stackable row">
      <div class="three wide column no-print">
        <section class="sticky bordered padded">
          <h4>
            <translate translate-context="*/*/Title/Name">
              Contents
            </translate>
          </h4>
          <toc v-if="renderToc" :content-element="$refs.content" :level="2" />
        </section>
      </div>
      <div class="nine wide column">
        <section class="heavily bordered padded" ref="content">
          <h1>
            <translate translate-context="*/*/*">Documentation for the Alta Call Client Space</translate>
          </h1>
          <p v-translate translate-context="Documentation/*/*">
            <em>The Alta Call executable</em> can be deployed, monitored and configured remotely through our web interface, or Client Space.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            The following documentation describes how to use our client space to perform common tasks and ensure Alta Call is deployed and configured properly.
          </p>
        
          <h2 v-translate translate-context="Documentation/*/Title">Install Alta Call</h2>
          <p v-translate translate-context="Documentation/*/*">
            The first step to use Alta Call is to download and install our executable.
            If you haven't done that already, <a href="/help/docs" class="internal">have a look at our documentation on the topic</a>.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            The rest of this documentation will assume you have already installed the executable.
          </p>
      
          <h2 v-translate translate-context="Documentation/*/Title">Generate a license key</h2>
          <p v-translate translate-context="Documentation/*/*">
            During installation, the executable will prompt you for a license key.
            <a href="/dashboard/group/manage" class="internal">License keys can be generated in a few clicks</a> and used for as many installations as you want.
          </p>

          <captioned-image
            :url="`${publicPath}docs/license-key.png`"
            alt="Screenshot of the web interface">
            <translate translate-context="Documentation/*/Caption">
              Generating a license key from the web interface
            </translate>
          </captioned-image>
          <p v-translate translate-context="Documentation/*/*">
            Once you have filled the license key in the executable and the installation is complete you can proceed to the next section.
          </p>
  
          <h2 v-translate translate-context="Documentation/*/Title">View users and machines</h2>
          <p v-translate translate-context="Documentation/*/*">
            <a href="/dashboard/group" class="internal">Through our dashboards</a>, you will get an exhaustive views of Alta Call within your organization.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            In particular, the dashboard home will give you some useful numbers regarding online users and machines.
          </p>
          <captioned-image
            :url="`${publicPath}docs/dashboard.png`"
            alt="Screenshot of the web interface">
            <translate translate-context="Documentation/*/Caption">
              Quick overview of Alta Call usage in the dashboard home
            </translate>
          </captioned-image>
          <p v-translate translate-context="Documentation/*/*">
            Machines and users are distinct concepts in Alta Call. The executable is installed on a machine, and can be used by many users.
            If Bob and Jane have two distinct Windows sessions and both of them use Alta Call on the same machine, the dashboards will display a single machine and two users.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            This means you don't have to think about which machine is used by which user.
            As long as distinct Windows sessions are involved, you will be able to monitor and manage individual users in the Client Space regardless of the machine that launched Alta Call.
          </p>
          
          <h2 v-translate translate-context="Documentation/*/Title">Browse users</h2>
          <p v-translate translate-context="Documentation/*/*">
            The <a href="/dashboard/group/users" class="internal">user dashboard</a> list all the users that have launched Alta Call.
            Depending on the number of Alta Call users in your organization, you might want to refine this list using the quick filters available in the header. 
          </p>
          <captioned-image
            :url="`${publicPath}docs/user-dashboard.png`"
            alt="Screenshot of the web interface">
            <translate translate-context="Documentation/*/Caption">
              Browsing users through the corresponding dashboard
            </translate>
          </captioned-image>
          <p v-translate translate-context="Documentation/*/*">
            Each row in the list includes useful information regarding the user, such as:
          </p>
          <ul>
            <li v-translate translate-context="Documentation/*/*">Their name</li>
            <li v-translate translate-context="Documentation/*/*">Their status (online or offline)</li>
            <li v-translate translate-context="Documentation/*/*">Configuration issues, if any</li>
            <li v-translate translate-context="Documentation/*/*">The audio transformation affected to them</li>
            <li v-translate translate-context="Documentation/*/*">Their current audio application (or softphone)</li>
          </ul>
          <h2 v-translate translate-context="Documentation/*/Title">View and change user configuration</h2>
          <p v-translate translate-context="Documentation/*/*">
            Clicking on a user name in the list will expand the row and offer you more information and options regarding their history, issues and settings.
          </p>
          <captioned-image
            :url="`${publicPath}docs/user-dashboard-detail.png`"
            alt="Screenshot of the web interface">
            <translate translate-context="Documentation/*/Caption">
              Expanded row in the user dashboard
            </translate>
          </captioned-image>
          <p v-translate translate-context="Documentation/*/*">Although you most likely won't have to do this, you can:</p>
          <ul>
            <li v-translate translate-context="Documentation/*/*">Lock the user to ensure they cannot change their configuration through the executable's UI</li>
            <li v-translate translate-context="Documentation/*/*">Select a different capture device</li>
            <li v-translate translate-context="Documentation/*/*">Select a different playback device</li>
            <li v-translate translate-context="Documentation/*/*">Select a different sampling rate</li>
          </ul>
          <p v-translate translate-context="Documentation/*/*">It may take up to a couple minutes before being configuration changes are effectively applied to the user.</p>
          <h2 v-translate translate-context="Documentation/*/Title">Batch configuration</h2>
          <p v-translate translate-context="Documentation/*/*">
            In addition to this, it is possible to change users configuration in batch. Each row in the list includes a checkbox.
            When at least one box is checked, a dedicated form is shown to quiclk perform certain tasks such as:
          </p>
          <ul>
            <li v-translate translate-context="Documentation/*/*">Assign another audio transformation</li>
            <li v-translate translate-context="Documentation/*/*">Assign or remove labels</li>
            <li v-translate translate-context="Documentation/*/*">Assign a different A/B Test</li>
          </ul>
          <captioned-image
            :url="`${publicPath}docs/user-dashboard-batch.png`"
            alt="Screenshot of the web interface">
            <translate translate-context="Documentation/*/Caption">
              Applying a batch action on several users
            </translate>
          </captioned-image>
          <p v-translate translate-context="Documentation/*/*">
            The top checkbox gives you the ability to apply the change to all the rows matching your current filters.
            This means, for instance, you only need a couple clicks to change the transformation of Online users who have a "Test" label, even if they are dozens.
          </p>
          <h2 v-translate translate-context="Documentation/*/Title">Browse machines</h2>
          <p v-translate translate-context="Documentation/*/*">
            In a similar fashion, the <a href="/dashboard/group/machines" class="internal">machine dashboard</a> list all the machines that have Alta Call installed.
            This tool can help you finding specific machines (by searching their IP address or hostname), find out which users where seen on a given machine, or understand which versions of Alta Call are currently in use.
          </p>
          <captioned-image
            :url="`${publicPath}docs/machine-dashboard.png`"
            alt="Screenshot of the web interface">
            <translate translate-context="Documentation/*/Caption">
              Browsing machines through the corresponding dashboard
            </translate>
          </captioned-image>
          <p v-translate translate-context="Documentation/*/*">
            Each row in the list includes useful information regarding the machine, such as:
          </p>
          <ul>
            <li v-translate translate-context="Documentation/*/*">Their hostname</li>
            <li v-translate translate-context="Documentation/*/*">Their public and private IP address</li>
            <li v-translate translate-context="Documentation/*/*">Their users</li>
            <li v-translate translate-context="Documentation/*/*">Configuration issues, if any</li>
          </ul>
          <captioned-image
            :url="`${publicPath}docs/machine-dashboard-detail.png`"
            alt="Screenshot of the web interface">
            <translate translate-context="Documentation/*/Caption">
              Expanded row in the machine dashboard
            </translate>
          </captioned-image>
          <h2 v-translate translate-context="Documentation/*/Title">Machine issues</h2>

          <p v-translate translate-context="Documentation/*/*">
            The <a href="/dashboard/group/issues" class="internal">issues dashboard</a> gives you an overview of configuration issues and step by step advice to pinpoint and solve common ones.
          </p>
          
          <captioned-image
            :url="`${publicPath}docs/issue-dashboard.png`"
            alt="Screenshot of the web interface">
            <translate translate-context="Documentation/*/Caption">
              Browsing issues through the corresponding dashboard
            </translate>
          </captioned-image>

          <h2 v-translate translate-context="Documentation/*/Title">Outdated machines</h2>

          <p v-translate translate-context="Documentation/*/*">
            Over time, we release new versions of Alta Call, that include new features, enhancements and bugfixes (including security bugfixes).
            It is recommended to install these upgrades as soon as possible.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            Generally speaking, any Alta Call version will work for at least 6 months. After that delay, it may be deprecated.
            Deprecated versions will stop working and must be upgraded.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            The <a href="/dashboard/group/issues" class="internal">outdated machines dashboard</a> will list machines that run a deprecated version of Alta Call, so that you can quickly identify these and take action.
          </p>
          
          <captioned-image
            :url="`${publicPath}docs/outdated-machines-dashboard.png`"
            alt="Screenshot of the web interface">
            <translate translate-context="Documentation/*/Caption">
              Browsing outdated machines through the corresponding dashboard
            </translate>
          </captioned-image>

          <h2 v-translate translate-context="Documentation/*/Title">User labels</h2>

          <p v-translate translate-context="Documentation/*/*">
            As the number of Alta Call users grows within your organization, it may become increasingly difficult to identify them.
            To help with that, you can associate <a href="/dashboard/group/labels" class="internal">labels</a> to your users, and use labels
            to quickly filter results in the users dashboard.
          </p>
          <captioned-image
            :url="`${publicPath}docs/labels.png`"
            alt="Screenshot of the web interface">
            <translate translate-context="Documentation/*/Caption">
              Managing user labels
            </translate>
          </captioned-image>
          <p v-translate translate-context="Documentation/*/*">
            Labels are entirely under your control and you are free to create and name them as you want.
            Common schemes include:
          </p>
          <ul>
            <li v-translate translate-context="Documentation/*/*">Per-client labels</li>
            <li v-translate translate-context="Documentation/*/*">Per-location labels</li>
          </ul>
          <p v-translate translate-context="Documentation/*/*">
            You can assign multiple labels to a single user.
          </p>

          <h2 v-translate translate-context="Documentation/*/Title">Groups</h2>

          <p v-translate translate-context="Documentation/*/*">
            When you start using Alta Call and our web interface, all the resources (users, machines, labels…) live in the same space and can be accessed and updated
            by anyone who has an access to the web interface.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            Depending on how your organization is structured, it may be necessary to implement access control and more granular permissions.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            Groups are designed exactly for this use case.
            You can 
            <a href="/organization/groups" class="internal">create as many groups as you want</a> for your organization and invite your team members in specific
            groups to ensure they can only access resources that are relevant to them.
          </p>

          <captioned-image
            :url="`${publicPath}docs/groups.png`"
            alt="Screenshot of the web interface">
            <translate translate-context="Documentation/*/Caption">
              Listing groups in your organization
            </translate>
          </captioned-image>

          <p v-translate translate-context="Documentation/*/*">
            Alta Call users and machines are associated to groups through their license key. If you want 
            a user or machine to be in a given group, ensure they use <a href="/dashboard/group/manage" class="internal">a license key created for this group</a>.
          </p>
          <p v-translate translate-context="Documentation/*/*">
            In the web interface, you will only view resources matching your currently selected group.
            If you are a member of at least two groups, you can use the selector in the web interface menu to quickly change your selected group.
          </p>

          <captioned-image
            :url="`${publicPath}docs/group-selector.png`"
            alt="Screenshot of the web interface">
            <translate translate-context="Documentation/*/Caption">
              Selecting a different group
            </translate>
          </captioned-image>

          <h2 v-translate translate-context="Documentation/*/Title">Invite other team members</h2>

          <p v-translate translate-context="Documentation/*/*">
            When you need other team members to access the web interface, 
            simply <a href="/dashboard/group/manage" class="internal">invite them</a> in the corresponding groups.
          </p>

          <captioned-image
            :url="`${publicPath}docs/invite.png`"
            alt="Screenshot of the web interface">
            <translate translate-context="Documentation/*/Caption">
              Inviting other team members
            </translate>
          </captioned-image>

          <h2 v-translate translate-context="Documentation/*/Title">Using our API</h2>
          <p v-translate translate-context="Documentation/*/*">
            In addition to our web interface, it is possible to access and manage resources using our REST API.
            Once you have generated <a href="/account" class="internal">your API Key</a>, 
             <a href="/help/api" class="internal">have a look at our API documentation</a> to get started.
          </p>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
import Toc from '@/components/TOC'
import CaptionedImage from '@/components/CaptionedImage'
export default {
  components: {
    Toc,
    CaptionedImage,
  },
  data () {
    return {
      renderToc: false,
      publicPath: process.env.BASE_URL
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.renderToc = true
    })
  }
}
</script>
