<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <section class="main transparent">
    <div class="stackable row">
      <div class="four wide column no-print">
        <section class="bordered padded">
          <h4>
            <translate translate-context="*/*/Title/Name">
              Contents
            </translate>
          </h4>
          <toc v-if="renderToc" :content-element="$refs.faq" :level="2" />
        </section>
      </div>
      <div class="eight wide column">
        <section class="heavily bordered padded" ref="faq">
          <h1>
            <translate translate-context="*/*/*">Frequently Asked Questions</translate>
          </h1>
          <h2 v-translate translate-context="FAQ/*/*">I would like to install Alta Call massively and I need a command line installation for this purpose, can I do it?</h2>
          <p v-translate translate-context="FAQ/*/*">
            Yes! You’re very welcome to install it massively!
          </p>
          <p v-translate translate-context="FAQ/*/*">
            For mass deployment, the installation can be done on the command line. The general command line arguments can be found <a href="https://jrsoftware.org/ishelp/index.php?topic=setupcmdline">here</a>.
          </p>
          <p v-translate translate-context="FAQ/*/*">
            The argument <code>/license</code> can also be used to specify your license while installing. For example, a full silent command line installation would be like:
          </p>
          <p v-translate translate-context="FAQ/*/*">
            <code>AltaCall-0.9.0-windows10-any.exe /license=THIS-IISS-MMYY-LICE-NSEE-IIDD /verysilent /norestart /suppressmsgboxes /<code>mergetasks</code>="!desktopicon"</code>
          </p>
          <p v-translate translate-context="FAQ/*/*">
            Note that if you install Voicemeeter together with Alta Call, it will still be necessary to reboot your computer in this installation mode.
          </p>
          <p v-translate translate-context="FAQ/*/*">
            Please replace the executable name above with the proper filename you have downloaded and replace the license identifier as well. Please replace the license ID as well with the one your received.
          </p>
          <p v-translate translate-context="FAQ/*/*">
            Finally, the <code>!desktopicon</code> command asks the installer to avoid installing the desktop icon launching Alta Voce, which can be useful to avoid users to launch Alta Call by mistake.
          </p>
          <h2 v-translate translate-context="FAQ/*/*">Missing <code>MFPlat.dll</code> (Microsoft Media Feature Pack)</h2>
          <p v-translate translate-context="FAQ/*/*">
            On recent Windows versions, this pack can be activated by following <a href="https://www.itechtics.com/install-media-feature-pack-in-windows-10/">this tutorial</a>. Otherwise, it can be downloaded and installed manually <a href="https://www.microsoft.com/en-us/software-download/mediafeaturepack">from here.</a>
          </p>
          <p v-translate translate-context="FAQ/*/*">
            It should also be possible to activate it through Powershell command lines:
            <pre><code>&gt; Get-WindowsOptionalFeature -Online</code><br><code>&gt; Enable-WindowsOptionalFeature -Online -FeatureName "MediaPlayback" -All</code><br><code>&gt; Enable-WindowsOptionalFeature -Online -FeatureName "WindowsMediaPlayer" -All</code></pre>
          </p>
          <p v-translate translate-context="FAQ/*/*">
            and then rebooting the computer is necessary.
          </p>
          <h2 v-translate translate-context="FAQ/*/*">Missing <code>MSVC*.dll</code> (Visual C++ Redistributable)</h2>
          <p v-translate translate-context="FAQ/*/*">
            You will solve this issue by installation the <em>Visual C++ Redistributable</em>.
          </p>
          <p v-translate translate-context="FAQ/*/*">
            Its installer is in the installation directory of Alta Call, a file of the name <code>vcredist_x64.exe</code>
          </p>
          <p v-translate translate-context="FAQ/*/*">
            Please restart the installation of Alta Call once this is done.
          </p>
          <h2 v-translate translate-context="FAQ/*/*"><code>Error: api-ms-win-crt-runtime-l1-1-0.dll is missing</code> (C Runtime)</h2>
          <p v-translate translate-context="FAQ/*/*">
            You will solve this issue by installing the <em>Universal C Runtime for Windows</em>. The installer is available by following <a href="https://support.microsoft.com/en-us/help/2999226/update-for-universal-c-runtime-in-windows">this link.</a>
          </p>
          <p v-translate translate-context="FAQ/*/*">
            Please restart the installation of Alta Call once this is done.
          </p>
          <h2 v-translate translate-context="FAQ/*/*">The Alta Call software complains about accessing internet, why and how do I fix this?</h2>
          <p v-translate translate-context="FAQ/*/*">
            AltaCall needs basic internet access to work properly. However, the audio will NEVER leave your machine. Only telemetry (e.g. algorithms usage, etc.) is sent to our servers and we need this information to perfect our service.
          </p>
          <p v-translate translate-context="FAQ/*/*">
            It is thus necessary to open the standard HTTPS port 443 on your firewall for AltaCall to work properly.
          </p>
          <p v-translate translate-context="FAQ/*/*">
            The destinations follow the pattern: <code>*.api.alta-voce.tech</code>, which might be requested by your firewall as well. If you need a destination for testing purpose, feel free to use <code>test.api.alta-voce.tech</code>
          </p>
          <p v-translate translate-context="FAQ/*/*">
            No worries, the amount of data used is fairly negligible compared to audio or video streaming services that you might be using (online radio and TV, podcasts, etc.), since <strong>no</strong> audio is sent to our servers.
          </p>
          <p v-translate translate-context="FAQ/*/*">
            If <em>Alta Call</em> cannot access our servers, the application will automatically switch to a <em><em><em>passthrough</em></em> </em>mode<em> (bypass)</em>. In such a situation, no  transformation will be applied, offering minimal latency. Importantly, this means that the application will not close on its own, nor interrupt the ongoing audio.
          </p>
          <h2 v-translate translate-context="FAQ/*/*">The audio stream fails with some devices</h2>
          <p v-translate translate-context="FAQ/*/*">
            Under Windows: It might be necessary to uncheck the box <em>“Allow software to take exclusivity”</em> in the Properties of the concerned audio devices. As shown in the following picture:
          </p>
          <img loading="lazy" src="https://old.alta-voce.tech/wp-content/uploads/2020/11/2020-11-17-remove-exclusivity-small.png" alt="">
          <p v-translate translate-context="FAQ/*/*">
            Handling on Windows 10:
          </p>
          <ol>
            <li v-translate translate-context="FAQ/*/List.Item">
              Right click on the speaker icon in the system tray
            </li>
            <li v-translate translate-context="FAQ/*/List.Item">
              <em>Open Sound Settings</em>
            </li>
            <li v-translate translate-context="FAQ/*/List.Item">
              <em>Sound Control Panel</em> in the column on the right
            </li>
            <li v-translate translate-context="FAQ/*/List.Item">
              Select the audio device you want to configure either in the <em>Playback</em> or <em>Recording</em> tabs
            </li>
            <li v-translate translate-context="FAQ/*/List.Item">
              Right click on the concerned device
            </li>
            <li v-translate translate-context="FAQ/*/List.Item">
              <em>Properties</em>
            </li>
            <li v-translate translate-context="FAQ/*/List.Item">
              <em>Advanced</em> tab
            </li>
            <li v-translate translate-context="FAQ/*/List.Item">
              Uncheck the box <em>“Allow software to take exclusivity”</em>
            </li>
          </ol>
          <p v-translate translate-context="FAQ/*/*">
            And do it for both your <em>Playback</em> and <em>Recording</em> devices.
          </p>
          <h2 v-translate translate-context="FAQ/*/*">The audio stream fails, after a very short success</h2>
          <p v-translate translate-context="FAQ/*/*">
            We experienced issues with USB 2.0 headsets plugged in a USB 3.0 port on Windows. Please try to simply change your USB port, favoring USB 2.0 ports (headsets do not require the speed a USB 3.0 can offer anyway).
          </p>
          <h2 v-translate translate-context="FAQ/*/*">No audio is going out of Alta Call and the vertical bars on the left are grey</h2>
          <p v-translate translate-context="FAQ/*/*">
            It Seems no audio is entering Alta Call. Please check the following:
          </p>
          <ul>
            <li v-translate translate-context="FAQ/*/List.Item">Your operating system might not give the right to access your audio device. For example, Windows has a security option in the settings panel to prevent Applications from accessing the microphone. Please check this setting and let Alta Call access the audio devices.</li>
            <li v-translate translate-context="FAQ/*/List.Item">Anti-virus might as well block access to audio devices. Please check the Alta Call can pass through antivirus restrictions.</li>
          </ul>
          <h2 v-translate translate-context="FAQ/*/*">Will the sound be louder or weaker with AltaCall?</h2>
          <p v-translate translate-context="FAQ/*/*">
            <em>Alta Call</em> is <strong>not</strong> supposed to change the loudness of the sound. If you feel the sound is louder or weaker, first switch to the <em>passthrough</em> mode (in this mode, <em>Alta Call</em> does not have the ability to change the loudness at all); then check, outside of <em>Alta Call</em>, that any other audio software and operating system settings are setup as you wish; and finally go back to the transformation mode you want in <em>Alta Call</em>.
          </p>
          <h2 v-translate translate-context="FAQ/*/*">I'm using virtual machines on my agents' computers. Should I do something particular?</h2>
          <p v-translate translate-context="FAQ/*/*">
            It is imperative to increase the buffersize of the VoiceMeeter virtual device to 8192, and restart the computer. The full configuration process is the following:
            <ul>
              <li><translate translate-context="Setup/*/*Paragraph">Run as admin the following software VoiceMeeter App (among the softwares installed with VoiceMeeter)</translate></li>
              <li><translate translate-context="Setup/*/*Paragraph">Click Menu in the upper right corner</translate></li>
              <li><translate translate-context="Setup/*/*Paragraph">Click System Settings / Options ...</translate></li>
              <li><translate translate-context="Setup/*/*Paragraph">Remplace value of Buffering MME with 8192</translate></li>
              <li><translate translate-context="Setup/*/*Paragraph">Remplace value of Buffering WDM with 8192</translate></li>
              <li><translate translate-context="Setup/*/*Paragraph">Remplace value of Buffering KS with 8192</translate></li>
              <li><translate translate-context="Setup/*/*Paragraph">Close the window System Settings / Options ...</translate></li>
              <li><translate translate-context="Setup/*/*Paragraph">Close the window VoiceMeeter App</translate></li>
              <li><translate translate-context="Setup/*/*Paragraph">Restart Computer</translate></li>
            </ul>

            Despites all the advantages of virtual machines, they add extra CPU processing all along the audio pipeline. Thus, if the buffersize is not increased, artefacts might be heard.
          </p>
          <h2 v-translate translate-context="FAQ/*/*">How can I automatically connect my telephony software to Alta Call?</h2>
          <p v-translate translate-context="FAQ/*/*">
            Some audio software are able to listen to the <em>default recording device</em>.
          </p>
          <p v-translate translate-context="FAQ/*/*">
            So you can simply set <em>Voicemeeter Output</em>(or <em>VB-Cable Output</em>) as the <em>default recording device</em> and let your operating system do its magic.
          </p>
          <p v-translate translate-context="FAQ/*/*">
            On Windows 10, you can do it manually through: <em>Settings</em> → <em>System</em> → <em>Sound</em> → <em>Sound Control Panel</em> (in the right column) → <em>Recording</em> tab → select <em>Voicemeeter Output</em> → click <em>Set Default</em> button below the list.
          </p>
          <p v-translate translate-context="FAQ/*/*">
            You can also automatise this procedure by using <a href="https://github.com/SomeProgrammerGuy/Powershell-Default-Audio-Device-Changer">this</a> script and thus do it with a Powershell command line:
          </p>
          <p v-translate translate-context="FAQ/*/*">
            <code>&gt; Set-DefaultAudioDevice (Get-CaptureDeviceId 'VoiceMeeter Output' 'VB-Audio VoiceMeeter VAIO')</code>
          </p>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
import Toc from '@/components/TOC'
export default {
  components: {
    Toc,
  },
  data () {
    return {
      renderToc: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.renderToc = true
    })
  }
}
</script>
